const FooterBanner = () => {
  //   let widthOfPage = window.innerWidth;

  let disableFooterImg = document?.getElementById(`disableFooterImg`);
  disableFooterImg?.addEventListener("contextmenu", (evf) => {
    evf?.preventDefault(); // this will prevent browser default behavior
  });
  // useEffect(() => {
  //   let element = document.getElementById("pdf-div");

  //   if (element)
  //     element.addEventListener("contextmenu", (event: any) =>
  //       event.preventDefault()
  //     );
  // }, []);

  return (
    // <section className="newsletter mb-15" style={{backgroundImage: "url(/images/banner/footer.jpg)",width:widthOfPage}}>
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-lg-12">
    //                 <div className="position-relative newsletter-inner"
    //                 // style={{minHeight:'418px'}}
    //                 >
    //                     {/* <div className="newsletter-content">
    //                         <h2 className="mb-20">
    //                             Stay home &amp; get your daily <br />
    //                             needs from our shop
    //                         </h2>
    //                         <p className="mb-45">
    //                             Start You'r Daily Shopping with{" "}
    //                             <span className="text-brand">Nest Mart</span>
    //                         </p>
    //                         <form className="form-subcriber d-flex">
    //                             <input type="email" placeholder="Your emaill address" />
    //                             <button className="btn" type="button">
    //                                 Subscribe
    //                             </button>
    //                         </form>
    //                     </div>
    //                     <img src="/images/banner/footer.jpg" alt="newsletter" /> */}
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </section>
    <>
      {/* <img
      id="disableFooterImg"
      src="/images/banner/footer.jpg"
      alt="banner"
      style={{ height: "100%", width: "100%" }}
    /> */}
    </>
  );
};

export default FooterBanner;
