import { useEffect } from "react";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="main pages" style={{ transform: "none" }}>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link href="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> Terms of Service
          </div>
        </div>
      </div>
      <div className="page-content pt-50" style={{ transform: "none" }}>
        <div className="container" style={{ transform: "none" }}>
          <div className="row" style={{ transform: "none" }}>
            <div
              className="col-xl-10 col-lg-12 m-auto"
              style={{ transform: "none" }}
            >
              <div className="row" style={{ transform: "none" }}>
                <div className="col-lg-12">
                  <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                    <div className="single-header style-2">
                      <h2>Terms of Service</h2>
                    </div>
                    <div className="single-content mb-50">
                      <p style={{ fontWeight: "bold" }}>
                        Must have a copy of your state tax resale certificate
                        and current business license
                      </p>
                      <p>1. The minimum order is $200.00.</p>
                      <p>
                        2. All prices are subject to change without notice and
                        we are not responsible for typographical errors.
                      </p>

                      <p>
                        3. Prices and images are subject to change without
                        notice.
                      </p>

                      <p>4. Pricing is FOB our warehouse in Lombard, IL.</p>
                      <h4>SHIPPING TERMS</h4>

                      <ol type="1">
                        <li>
                          {" "}
                          Your shipping/handling fee is determined by the
                          package’s weight , number of boxes and destination.
                          Value is also determined for insurance purposes only.
                          Some orders are shipped in multiple packages.{" "}
                        </li>
                        <li>
                          {" "}
                          Unless otherwise specified, all shipments are sent UPS
                          Ground Service. UPS Expedited services are available
                          upon request for a fee.{" "}
                        </li>
                        <li>
                          {" "}
                          Free Shipping will be provided for shipments above
                          $500 (Business addresses only) going to the following
                          states unless otherwise specified IL, IN, KY, WI, MI,
                          OH, TN, IA, MO, PA, FL, NC, SC. All additional states
                          will be provided with half off on shipping with $500
                          or more in purchase.
                          <ul>
                            <li>
                              {" "}
                              No additional fees are charged except rates
                              provided by UPS.{" "}
                            </li>
                            <li>
                              {" "}
                              Service charge of $13 will be applied to all C.O.D
                              shipments.{" "}
                            </li>
                            <li>
                              {" "}
                              We do not ship to APO/FPO, P.O Box addresses.{" "}
                            </li>
                            <li>
                              {" "}
                              For All shipment to overseas customers are
                              responsible for taxes and custom duties.
                            </li>
                          </ul>
                        </li>
                      </ol>

                      <h4> PAYMENT TERMS </h4>

                      <ol type="1">
                        <li>
                          {" "}
                          We accept all major credit cards (VISA, MASTER CARD,
                          AMERICAN EXPRESS, DISCOVER)
                          <ul>
                            <li>
                              {" "}
                              A credit card Authorization form must be signed
                              and on file prior to shipping any order.{" "}
                              <a href="https://clwholesale.com/download_pdf.php?file=images/cl Wholesale Credit Card Agreement.pdf">
                                (Download HERE)
                              </a>{" "}
                            </li>
                          </ul>
                        </li>
                      </ol>

                      <h4>RETURN POLICY</h4>
                      <ol type="1">
                        <li>
                          {" "}
                          All return merchandise must be notified within 24-48
                          hours of order receipt and must be accompanied by RMA
                          (Return Merchandise Authorization) number. To obtain a
                          RMA number email us contact@cloudlinewholesale.com or
                          call us at 1 (855) LIKE-SWI. No credit card, check or
                          bank refunds will be issued, items may be exchanged or
                          you will receive store credit on your next purchase.
                        </li>
                        <li>
                          {" "}
                          Merchandise having a manufacturer’s defect will be
                          accepted for replacement or credit. Misused or
                          improperly cared for products will not be accepted.{" "}
                        </li>
                        <li>
                          {" "}
                          PACKAGING, Refused and Return Items Instruction:
                          <ul>
                            <li>
                              {" "}
                              Send your return package back to CL Wholesale,
                              Inc. via the Post Office, UPS or other service.
                              (We are not responsible for non receipt of
                              returned merchandise.){" "}
                            </li>
                            <li>
                              {" "}
                              Include name, Order number, copy of invoice, and
                              Item number(s) of enclosed merchandise.{" "}
                            </li>
                            <li>
                              {" "}
                              Utilize the original packaging if possible and
                              completely remove or cover the original shipping
                              label. (Please use a padded envelope or box if
                              original shipping materials are not reusable.
                              Items received damaged, unsealed or not in new
                              condition will not be honored.){" "}
                            </li>
                            <li>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Refused Items : If its fault of CL Wholesale,
                                Inc., you will be fully credited and or items
                                will be reshipped at our expense.{" "}
                              </span>{" "}
                              <span style={{ color: "red" }}>
                                {" "}
                                On all other refused orders, the customer will
                                be charged a 10% restocking fee plus all freight
                                charges.{" "}
                              </span>{" "}
                              If the customer wants the order reshipped, the
                              freight charges will be added. Only manufacturer
                              defects or items with concealed damages as a
                              result of the shipping will be authorized for a
                              return. The merchandise returned must be in the
                              original packaging and free of all customer's
                              marking and tags.
                            </li>
                            <li>
                              {" "}
                              Keep your shipping receipt until you have received
                              exchanged item.{" "}
                            </li>
                          </ul>
                        </li>
                      </ol>
                      <h5 style={{ color: "red" }}>
                        Release, Assumption of Risk, Waiver of Liability, and
                        Indemnity Agreement{" "}
                      </h5>

                      <p>
                        {" "}
                        By purchasing any item sold by CL Wholesale, Inc., the
                        buyer expressly warrants that he/she is in compliance
                        with all applicable Federal, State, and Local laws and
                        regulations regarding the purchase, ownership, and use
                        of the item. It shall be the buyers’ responsibility to
                        comply with all Federal, State, and Local laws governing
                        the sale of any items listed, illustrated or sold. The
                        buyer expressly agrees to indemnify and hold harmless CL
                        Wholesale, Inc. for all claims resulting directly or
                        indirectly from the purchase, ownership, or use of the
                        item in violation of applicable Federal, State, and
                        Local laws or regulations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TermsConditions;
