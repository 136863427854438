import { useEffect } from "react";
import { Link } from "react-router-dom";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="main pages">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <Link to="/" rel="nofollow">
              <i className="fi-rs-home mr-5" />
              Home
            </Link>
            <span /> About us
          </div>
        </div>
      </div>
      <div className="page-content pt-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <section className="row align-items-center mb-50">
                <div className="col-lg-12">
                  <div className="pl-25">
                    <h2 className="mb-30">About Us</h2>
                    <p className="mb-25">
                      CL Wholesale, Inc. was established with the principles of
                      Honesty, courtesy, Sincerity and plain simple excellent
                      customer service. We at SWI strongly believe in, “Treating
                      people as you like to be treated.” Just like our name
                      suggests there is always a silver line after a dark cloud,
                      we would like to be the silver line in this smoking
                      accessory market by providing you with the hottest new
                      products from our industry. Our goal is to provide high
                      quality products for lowest price possible to all of our
                      customers.
                    </p>
                    <p className="mb-50">
                      We are probably the first in the industry to offer a
                      reward program for our customers. Shopping with us on our
                      site would earn you reward points that you can redeem for
                      various promotions.
                    </p>
                    <p className="mb-50">
                      To Place an order, request a free catalog or get a
                      password to visit our site, call our-sales department at{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        1-630-785-6838{" "}
                      </span>{" "}
                      or email us at{" "}
                      <a
                        className="contact-email"
                        href="mailto:contact@cloudlinewholesale.com"
                      >
                        contact@cloudlinewholesale.com
                      </a>
                    </p>

                    <p className="mb-50">Thank you for visiting</p>

                    <p className="mb-50">
                      <a
                        className="contact-web"
                        target="_blank"
                        href="http://clwholesale.com/index.php"
                      >
                        {" "}
                        www.clwholesale.com
                      </a>
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AboutUs;
