import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveItems = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/items?category=${data.category}&brands=${data.brands}&per_page=${data.per_page}&page=${data.page}&min_price=${data.min_price}&max_price=${data.max_price}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveRelatedItems = async (category_id, item_id) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/related-items?category_id=${category_id}&except_item=${item_id}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveRelatedGroupDetails = async (category_id, item_id) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/group-item-detail?groupId=${category_id}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveHomePageItems = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/home-page-items", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retrivePromotedItems = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/promoted-items", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveFrequentlyOrderedItems = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/frequently-order-items", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveItem = async (item_id) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/items/${item_id}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveGroupDetails = async (item_id) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/group-item-detail?groupId=${item_id}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveItemByIds = async (item_ids) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(`/customer/wishlist-list`, {}, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const wishlistCount = async (item_ids) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  let formdata = new FormData();
  formdata.append("count", 1);
  return new Promise(function (resolve, reject) {
    axios
      .post(`/customer/wishlist-list`, formdata, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        // handleAuthFailure(error, reject);
      });
  });
};

const wishlistClearApi = async (item_ids) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(`/customer/remove-wishlist`, item_ids, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveRecentViewedItems = async (item_ids, except_id) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/recent-viewed-items?ids=${item_ids}&except_id=${except_id}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const addItemToCart = async (item_ids, quantity = 1) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/add-to-cart?item_id=${item_ids}&quantity=${quantity}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const addItemToWishlist = async (item_ids) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(`/customer/add-wishlist`, item_ids, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const addSelectedItemToCart = async (item_ids, quantity = 1) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(
        `/customer/add-selected-to-cart?quantity=${quantity}`,
        { item_ids: item_ids },
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};
const retriveSearchItems = async (query, page = 1, per_page = 10) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/search-group-items?query=${query}&page=${page}&per_page=${per_page}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

function handleAuthFailure(error, reject) {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");

    if (window.location.pathname != "/login") {
      window.location.href = "/login";
    }
  } else {
    reject(error);
  }
}

export {
  retriveItems,
  retriveItem,
  retriveGroupDetails,
  retriveHomePageItems,
  retriveRelatedItems,
  retriveItemByIds,
  wishlistCount,
  retriveRecentViewedItems,
  addItemToCart,
  addItemToWishlist,
  addSelectedItemToCart,
  wishlistClearApi,
  retrivePromotedItems,
  retriveFrequentlyOrderedItems,
  retriveSearchItems,
};
