import { useEffect } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
    return (
        
        <main className="main pages" style={{ transform: "none" }}>
  <div className="page-header breadcrumb-wrap">
    <div className="container">
      <div className="breadcrumb">
        <Link href="/" rel="nofollow">
          <i className="fi-rs-home mr-5" />
          Home
        </Link>
        <span /> Privacy Policy
      </div>
    </div>
  </div>
  <div className="page-content pt-50" style={{ transform: "none" }}>
    <div className="container" style={{ transform: "none" }}>
      <div className="row" style={{ transform: "none" }}>
        <div
          className="col-xl-10 col-lg-12 m-auto"
          style={{ transform: "none" }}
        >
          <div className="row" style={{ transform: "none" }}>
            <div className="col-lg-12">
              <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                <div className="single-header style-2">
                  <h2>Privacy Policy</h2>
                </div>
                <div className="single-content mb-50">
                  <p> We collect the e-mail addresses of those who communicate with us via e-mail, aggregate information on what pages consumers access or visit, information volunteered by the consumer, such as survey information and/or site registrations.</p>
                  <p> The information we collect is used for internal review and is then discarded.</p>
                  <p> With respect to cookies: We use cookies to record session information, such as items that consumers add to their shopping cart and record affiliates sales if a sale has been made via a link from an affiliates website. If you supply us with your postal address on-line you will only receive the information or merchandise for which you provided us your address. Persons who supply us with their telephone numbers on-line will only receive telephone contact from us with information regarding orders they have placed on-line.</p>
                  <p> With respect to Ad Servers: We do not partner with or have special relationships with any ad server companies. Upon request we provide site visitors with access to transaction information (e.g., dates on which customers made purchases, amounts and types of purchases) that we maintain about them, contact information (e.g., name, address, phone number) that we maintain about them, or a description of information that we maintain about them.</p>
                  <p> Consumers can access this information by e-mailing us at the above address or writing to us at the above address. Consumers can have this information corrected by sending us e-mail at the above address, calling us at the above telephone number, writing to us at the above address </p>
                  <p> As part of the order process, the following information is collected from shoppers: </p>
                  <p> Name, Shipping/Billing Address, Email address, Phone number, Credit/Debit Card Information.</p>
                  <p> <strong> How That Information Is Used: </strong> </p>
                  <p> Any information we collect is strictly used to process your order. We will not share or sell any of your personal information with Third Parties. We may occasionally contact you in regards to special offers via email. </p>
                  <p> Contact Us </p>
                  <p> <a className="contact-email" href="mailto:contact@cloudlinewholesale.com">contact@cloudlinewholesale.com</a> or Toll Free at <strong> 1-855-LIKE-SWI (545-3794) </strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
    );
}

export default PrivacyPolicy;